(function ($) {
  var search_popup = $('#search-popup');
  var locator_popup = $('#residence_locator');
  var global_overlay = $('#site-menu-overlay');
  var popup_overlay = $('#site_popup_overlay, #site-menu-overlay');

  function open_popup(button, popup, overlay, el_to_focus) {
    button.attr('aria-expanded', 'true');
    popup.addClass('opened');
    overlay.addClass('active');
    trap_focus_inside(popup);

    if (el_to_focus) {
      el_to_focus.focus();
    }

    $('body').css({
      'height': '100%',
      'overflow': 'hidden'
    });
  }

  function close_popup(button, popup, overlay) {
    button.attr('aria-expanded', 'false');
    popup.removeClass('opened');
    overlay.removeClass('active');
    untrap_focus_inside(popup);
    $('body').css({
      'height': 'auto',
      'overflow': 'visible'
    });
  } // ON READY


  $(function () {
    $('#search-toggle').on('click', function (e) {
      open_popup($(this), search_popup, global_overlay, $('#search'));
    });
    $('#search-close').on('click', function (e) {
      close_popup($('#search-toggle'), search_popup, global_overlay);
    });
    $('#locator-toggle').on('click', function (e) {
      if (locator_popup.hasClass('opened')) {
        close_popup($('#locator-toggle'), locator_popup, popup_overlay);
        $('#locator-toggle').removeClass('expanded');
      } else {
        open_popup($('#locator-toggle'), locator_popup, popup_overlay);
        $('#locator-toggle').addClass('expanded');
      }
    });
    popup_overlay.on('click', function (e) {
      if (locator_popup.hasClass('opened')) {
        close_popup($('#locator-toggle'), locator_popup, popup_overlay);
        $('#locator-toggle').removeClass('expanded');
      }
    });
    $('#locator-close').on('click', function (e) {
      if (locator_popup.hasClass('opened')) {
        close_popup($('#locator-toggle'), locator_popup, popup_overlay);
        $('#locator-toggle').removeClass('expanded');
      }
    });
    $('.site-header__top').on('click', function (e) {
      var target = $(e.target);

      if (locator_popup.hasClass('opened') && 0 === target.parent('#locator-toggle').length && 0 === target.parents('#residence_locator').length && !target.hasClass('residence_locator')) {
        e.preventDefault();
        close_popup($('#locator-toggle'), locator_popup, popup_overlay);
        $('#locator-toggle').removeClass('expanded');
      }
    });
    global_overlay.on('click', function (e) {
      if (search_popup.hasClass('opened')) {
        close_popup($('#search-toggle'), search_popup, global_overlay);
      }
    });
  });
})(jQuery);