(function ($) {
  function setCookie(key, value) {
    var expires = new Date();
    expires.setTime(expires.getTime() + 1 * 24 * 60 * 60 * 1000);
    document.cookie = key + '=' + value + ';expires=' + expires.toUTCString() + ';path=/';
  }

  $(document).ready(function () {
    $('#alert').find('.alert-close').on('click', function (e) {
      e.preventDefault();
      e.stopPropagation();
      $('#alert').slideUp('fast').addClass('disabled');
      $('body').removeClass('with-alert');
      setCookie('alertbar', 'false');
    });
  });
})(jQuery);