var elements_with_trapped_focus = [];
var trapped_elements_id = 0;

function get_focusable_children_of($el) {
  var $focusables = $el.find('[tabindex="0"], a, input, textarea, button').not('[tabindex="-1"]').filter(':visible');
  var $first = $focusables.first();

  if ($first.is('[type="radio"]')) {
    $el.find('[name="' + $first.attr('name') + '"]').addClass('first-focusable').data('trapped-in', trapped_elements_id);
  } else {
    $first.addClass('first-focusable').data('trapped-in', trapped_elements_id);
  }

  var $last = $focusables.last();

  if ($last.is('[type="radio"]')) {
    $el.find('[name="' + $last.attr('name') + '"]').addClass('last-focusable').data('trapped-in', trapped_elements_id);
  } else {
    $last.addClass('last-focusable').data('trapped-in', trapped_elements_id);
  }

  return $focusables;
}

function get_trap_by_id(trap_id) {
  for (var i = elements_with_trapped_focus.length - 1; i >= 0; i--) {
    if (trap_id === elements_with_trapped_focus[i].data('trap-id')) {
      return elements_with_trapped_focus[i];
    }
  }

  return false;
}

function check_focus_position(e) {
  var $focused = jQuery(e.target);
  var $trap; // Is going reverse

  if (e.shiftKey) {
    if ($focused.hasClass('first-focusable')) {
      $trap = get_trap_by_id($focused.data('trapped-in'));

      if ($trap) {
        e.preventDefault();
        var $last = $trap.find('.last-focusable');

        if ($last.is('[type="radio"]')) {
          $last.filter(':checked').focus();
        } else {
          $last.focus();
        }
      }
    } // Focus is going forward

  } else {
    if ($focused.hasClass('last-focusable')) {
      $trap = get_trap_by_id($focused.data('trapped-in'));

      if ($trap) {
        e.preventDefault();
        var $first = $trap.find('.first-focusable');

        if ($first.is('[type="radio"]')) {
          $first.filter(':checked').focus();
        } else {
          $first.focus();
        }
      }
    }
  }
}

function untrap_focus_inside($el) {
  var trap_id = $el.data('trap-id');

  for (var i = elements_with_trapped_focus.length - 1; i >= 0; i--) {
    if (trap_id === elements_with_trapped_focus[i].data('trap-id')) {
      // Completely remove focus information from object
      elements_with_trapped_focus[i].removeData('trap-id').removeClass('focus-trap').off('keydown.trap'); // Remove element from array

      elements_with_trapped_focus.splice(i, 1); // Job's done.

      break;
    }
  }
}

function trap_focus_inside($el) {
  if ($el.hasClass('focus-trap')) {
    return;
  }

  trapped_elements_id++;
  $el.addClass('focus-trap');
  $el.data('trap-id', trapped_elements_id);
  $el.allFocusableChildren = get_focusable_children_of($el);
  elements_with_trapped_focus.push($el);
  $el.on('keydown.trap', function (e) {
    // SHIFT
    if (9 === e.keyCode) {
      check_focus_position(e);
    }
  });
}

(function ($) {
  function check_resize() {}

  function init_resize_events() {
    check_resize();
    $(window).on('resize', function () {
      check_resize();
    });
  }

  function init_components() {} // detects if user is using the keyboard to navigate and if so, adds a class to body


  function handleFirstTab(e) {
    if (e.keyCode === 9 || e.keyCode === 32 || e.keyCode === 13 || e.keyCode === 37 || e.keyCode === 39) {
      // the "I am a keyboard user" key
      document.body.classList.add('user-is-tabbing');
      window.removeEventListener('keydown', handleFirstTab);
    }
  } // ON READY


  $(function () {
    init_components();
    init_resize_events();
    window.addEventListener('keydown', handleFirstTab); // fake select accordions

    if ($('.accordion-select').length > 0) {
      $('.accordion-select .select__header').each(function () {
        $(this).on('click', function () {
          var select = $(this).parent('.accordion-select');

          if (select.hasClass('opened')) {
            select.removeClass('opened');
            $(this).attr('aria-expanded', 'false');
          } else {
            select.addClass('opened');
            $(this).attr('aria-expanded', 'true');
          }
        });
      });
    }
  });
})(jQuery); // Fully reference jQuery after this point.